<template>
	<v-app>
		<v-main class="d-flex flex-row align-center">
			<v-row
				v-if="isVerified == true"
				class="d-flex flex-column align-center"
			>
				<v-icon size="120" color="success">mdi-check-decagram</v-icon>

				<h1 class="text-h4 mt-5">Account verified successfully!</h1>

				<p class="text-subtitle-1 mt-5">
					You will be redirected automatically to your dashboard in
					<strong> {{ timerCount }} seconds </strong>
				</p>

				<p class="text-subtitle-1">
					If you are not automatically redirected then
					<router-link to="/project">click here</router-link>
				</p>
			</v-row>

			<v-row v-else class="d-flex flex-column align-center">
				<v-icon size="120" color="warning">mdi-alert</v-icon>

				<p class="text-subtitle-1 mt-5">
					The link may have already been used or may have expired.
				</p>

				<p class="text-subtitle-1">
					Please login using your credentials from
					<router-link to="/login">here</router-link>
				</p>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
export default {
	data() {
		return {
			isVerified: false,
			timerCount: 10,
		}
	},
	watch: {
		timerCount: {
			handler(value) {
				if (value > 0) {
					setTimeout(() => {
						this.timerCount--
					}, 1000)
				}
			},
			immediate: true,
		},
	},
	beforeCreate() {
		this.$cookies.remove('x-auth-token')
		this.axios
			.get(`/auth/verify-user?token=${this.$route.query.token}`)
			.then((res) => res.data)
			.catch((err) => {
				const data = err.response.data
				this.message = data.message
				throw new Error(err)
			})
			.then((res) => {
				if (res.success) {
					this.isVerified = res.success
					this.message = res.message
					this.$cookies.set('x-auth-token', res.token)
					setTimeout(() => {
						this.$router.push('/projects')
					}, 10000)
				}
			})
			.catch((err) => {
				throw new Error(err)
			})
	},
}
</script>
